import React, { useMemo, useEffect, useRef, useState, Suspense } from "react";
import * as THREE from "three";
import { useThree } from "react-three-fiber";

const Webcam = ({ videoElementId, ...props }) => {
  //   const texture = useMemo(() => new THREE.VideoTexture(video), [video]);

  const [aspect, setAspect] = useState(1.0);

  const mesh = useRef();
  const material = useRef();

  useEffect(() => {
    const video = document.getElementById(videoElementId);

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      var constraints = {
        video: { width: 1280, height: 720, facingMode: "user" },
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          // apply the stream to the video element used in the texture

          video.srcObject = stream;
          video.play();

          const texture = new THREE.VideoTexture(video);
          const videoMaterial = new THREE.MeshBasicMaterial({ map: texture });

          // THEORY: Seems like you can't update the tex associated with a material if it's a VideoTexture,
          // thus we swap the material rather than the texture.
          mesh.current.material = videoMaterial;
        })
        .catch(function (error) {
          console.error("Unable to access the camera/webcam.", error);
        });
    } else {
      console.error("MediaDevices interface not available.");
    }
  }, [videoElementId]);

  return (
    <mesh ref={mesh} scale={[0.5, 0.5, 0.5]} {...props}>
      <planeBufferGeometry attach="geometry" args={[16, 9]} />
      <meshBasicMaterial ref={material} attach="material" color={[0, 255, 0]} />
    </mesh>
  );
};

export default Webcam;
